<template>
  <div>
    <div class="flex-row flex-row-between">
      <!-- <h5>查看您的收货地址</h5> -->
    </div>
    <a-spin :spinning="loading" tip="Loading...">
      <!-- <a-card style="width: 500px"> -->
      <template v-if="defaultAddress === null">
        <a-result status="warning" title="您还没有默认地址数据">
          <template #extra>
            <a-button key="console" type="primary" @click="addAddressModal = true">添加地址</a-button>
          </template>
        </a-result>
      </template>
      <template v-else>
        <div class="flex-row">
          <div >
            <p class="pl-20" style="margin-top:15px;">寄送至:
              <span style="margin-left:10px;">
                {{ defaultAddress.provinceName + defaultAddress.cityName + defaultAddress.countyName + defaultAddress.detailInfo }}
              </span>
            </p>
            <p class="pl-20" style="margin-top:5px;">收货人:
              <span style="margin-left:10px">
                {{ defaultAddress.userName }}
              </span>
              <span style="margin-left:10px">
                {{ defaultAddress.telNum }}
              </span>
            </p>

          </div>

          <a-button
            shape="round"
            size="small"
            style="margin-top: 10px; margin-left: 10px;"
            type="link"
            @click="ModalAddressSelect = true">
            <img :src="require('@/assets/address.png')">
          </a-button>
        </div>
      </template>
      <!-- </a-card> -->
    </a-spin>
    <AddressModal v-model="addAddressModal" @ok="getData"></AddressModal>
    <AddressSelectModal v-model="ModalAddressSelect" :closeMo="closeMo" @del="getData" @select="modalSelectAddress" @selectIcount="selectIcount"></AddressSelectModal>
  </div>
</template>

<script>
import { getDefaultAddress } from '@/api/Address'
import AddressSelectModal from '@/components/AddressSelect/Modal/AddressSelectModal'
import AddressModal from '@/views/User/Address/AddressModal'

/**
 * @author  XuHongli
 * @date  2022/9/8 14:49
 * @version 1.0
 * @description 地址选择器 返回地址id
 */
export default {
  name: 'AddressSelect',
  components: { AddressModal, AddressSelectModal },
  data() {
    return {
      defaultAddress: {},
      loading: false,
      ModalAddressSelect: false,
      addAddressModal: false,
      closeMo:''
    }
  },

  created() {
    this.getData()
  },
  methods: {
    selectIcount(data){
      console.log('dadasdada',data)
      this.defaultAddress = data
      this.closeMo = data.id
      this.$emit('input', this.defaultAddress)
      this.ModalAddressSelect = false
    },
    getData() {
      this.loading = true
      getDefaultAddress().then((res) => {
        this.loading = false
        this.defaultAddress = res.data
        console.log('input',res.data)
        this.$emit('input', this.defaultAddress)
        this.$emit('cityIdChang',res.data)
      })
    },
    modalSelectAddress(val) {
      console.log(val,'dsdsd')
      this.defaultAddress = val
      // this.$emit('input', this.defaultAddress)
      this.$emit('cityIdChang',val)
    }
  },
}
</script>

<style lang="scss" scoped>
.ant-result {
  padding: 0;
}
</style>
