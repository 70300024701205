<template>
  <div class="CheckOrder main-content bg-white">
    <div class="bg-white" style="position: absolute;width: 100%;height: 100%;transform: translate(-50%,-50%);z-index: -1;top: 50%;left: 50%"></div>
    <p class="text-danger text-lg" style="margin-top:50px;font-size: 22px">商品信息</p>
    <div class="order">
      <div class="dis">
        <div class="tb-item1">
          <div class="order-tb " style="border-left: none;">商品</div>
          <div class="dis tb-bottom">
            <img :src="skuInfo.picUrl || spuInfo.imgUrl" alt="" style="width:60px;height: 60px;margin-right: 10px;">
            <div class="shopheiz">
              <div >
                <Ellipsis :length="65" tooltip>{{ spuInfo.name }}</Ellipsis>
              </div>
              <div style="margin-top:10px;">
                <span v-for="(spec,index) in skuInfo.specNames" :key="index">{{ spec + '&nbsp;&nbsp;' }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="tb-item2">
          <div class="order-tb" style="">规格</div>
          <div class="tb-bottom">
            <span v-for="(spec,index) in skuInfo.specNames" :key="index">{{ spec + '&nbsp;&nbsp;' }}</span>
          </div>
        </div>
        <div class="tb-item2">
          <div class="order-tb " style="">规格价</div>
          <div class="tb-bottom">
            <span>{{ '￥' + skuInfo.tradePrice?.toFixed(2) }}</span>
          </div>
        </div>
        <div class="tb-item3">
          <div class="order-tb" style="">数量</div>
          <div class="tb-bottom">
            <span>{{ 'x' + $route.params.quantity }}</span>
          </div>
        </div>
        <div class="tb-item3" >
          <div class="order-tb" style="">商品总价</div>
          <div class="tb-bottom">
            <span>{{ '￥' + ($route.params.quantity*skuInfo.tradePrice)?.toFixed(2) }}</span>
          </div>
        </div>
        <div class="tb-item3" >
          <div class="order-tb" style="border-right: none;">运费</div>
          <div class="tb-bottom">
            <span>￥ {{ freightPrices?.toFixed(2) }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-flow mt-20">
      <p class="pl-20">订单备注：</p>
      <textarea v-model="remarks" class="remark-box" maxlength="100" placeholder="请填写订单备注信息（限100字以内）"></textarea>
    </div>
    <div class="disFixe" style="margin-right: 30px;">
      <div class="flex-flow" style="margin-top:15px;">
        <!-- <p class="pl-20" style="margin-left:15px;">寄送至：</p> -->
        <div>
          <AddressSelect v-model="addressInfo" @cityIdChang="change"></AddressSelect>
        </div>
      </div>
      <div class="pl-20 righ" >
        <div class="disFixe" style="justify-content: space-between;">
          <div class="pl-20" style="margin-left:30px;width:80px;">总金额： </div>
          <span style="font-size: 18px;">{{ '￥'+ ($route.params.quantity*skuInfo.tradePrice).toFixed(2) }}</span>
        </div>
        <div class="disFixe">
          <div class="pl-20" style="margin-left:15px;">运费：</div>
          <span style="font-size: 18px;" >￥ {{ freightPrices?.toFixed(2) }}</span>
        </div>
      </div>
    </div>

    <div style="text-align: right; font-weight: 700; margin-right: 30px;">
      应付合计：
      <span style=" font-size: 18px; color: #F5222D">
        ￥{{ (Number(skuInfo.tradePrice * $route.params.quantity )+Number(freightPrices)).toFixed(2) }} </span>元
    </div>
    <div class="p-10" style="text-align: right;margin-right:20px;">
      <a-button :loading="loading" type="danger" @click="submitOrder()">提交订单</a-button>
    </div>
  </div>
</template>

<script>
import { notification } from 'ant-design-vue'
import AddressSelect from '@/components/AddressSelect'
import { addOrder ,getUserOrderObj} from '@/api/Order'
import Ellipsis from '@/components/Ellipsis/Ellipsis'
import { addUserAddress, updateUserAddress, getRegionTree } from '@/api/Address'
import { mapGetters } from 'vuex'
import { freightProduct } from '@/api/Product'



/**
 * @author  XuHongli
 * @date  2022/9/8 14:17
 * @version 1.0
 * @description 确认订单
 */
export default {
  name: 'CheckOrder',
  components: { Ellipsis, AddressSelect },
  data() {
    return {
      addressInfo: '',
      skuInfo: '',
      spuInfo: {},
      model:false,
      visible:false,
      loading: false,
      layout: {
        labelCol: { span: 7 },
        wrapperCol: { span: 14 },
      },
      form: this.$form.createForm(this),
      options: [],
      areaAddressId: '',
      remarks:'',
      OrderId:'',
      freightPrices:'',//运费,
      cityId:'' //我的收货地址id
    }
  },
  created() {
    console.log('路由', this.$route)
    if (this.$route.params.skuInfo) {
      this.skuInfo = this.$route.params.skuInfo
      this.spuInfo = JSON.parse(this.$route.params.spuInfo)
    } else {
      this.$router.go(-1)
    }
      getRegionTree().then(res => {
        this.options = res.data
      })
      this.freightPrices = this.$route.params.freightPrices
    this.GetFreightProduct()
  },
  methods: {
    // 点击选择我的收货地址
    change(data){
      console.log('点击选择我的收货地址',data)
      if (!data) return
      this.cityId = data.cityId
      this.addressInfo.id = data.id
      this.GetFreightProduct()
    },
    GetFreightProduct(){
      console.log('提交this.cityId', this.cityId)
      if (this.cityId) {
        var data = {
          cityId: this.cityId, // 我的地址id
          productId: this.$route.params.productId, //商品id
          productNum: this.$route.params.quantity, //商品数量
          skuId: this.skuInfo.id  //skuid
        }
        freightProduct(data).then(res => {
          console.log('根据地址查运费',res.data)
          this.freightPrices = res.data

        })
      }
    },
    onChange(value, selectedOptions) {
      this.areaAddressId = selectedOptions[2].id
    },
    closeModal(){
      this.visible = false
      this.form = {}
    },
    handleOk() {
      this.form.validateFields((err, values) => {
        if (!err) {
          const data = Object.assign(values, {
            provinceName: values.area[0],
            cityName: values.area[1],
            countyName: values.area[2],
            areaAddressId: this.areaAddressId
          })
          delete data.area
          if (this.model == true) {
            updateUserAddress({ ...data, id: this.model.id }).then((res) => {
                notification.success({
                  message: res.msg,
                  description: res.msg,
                })
              this.$emit('ok')
              this.closeModal()
            })
          } else if(this.model == false) {
            addUserAddress(data).then((res) => {
              notification.success({
                message: res.msg,
                description: res.msg,
              })
              this.$emit('ok')
              this.closeModal()
            })
          }
        }
      })
    },
    add(){
      this.model = false
      this.visible = true
    },

    submitOrder() {
      console.log('提交', this.addressInfo)
      this.loading = true
      if (this.addressInfo) {
        const queryData = {
          addressId: this.addressInfo.id,
          productInfoList: [
            {
              productId: this.spuInfo.id,
              skuId: this.skuInfo.id,
              quantity: this.$route.params.quantity
            }
          ],
          remarks: this.remarks
        }
        addOrder(queryData).then((res) => {
          this.loading = false
          this.$router.push({ name: 'OrderDetail', params: { id: res.data.id } })
        }).catch(err => {
          this.loading = false
        })
      } else {
        this.loading = false
        this.$message.warning('请先设置地址！')
      }

    }
  },
}
</script>

<style lang="scss" scoped>
body {
  background-color: white !important;
}
.righ{
  border-bottom: 1px solid #999;
  margin-bottom: 20px;
}
.disFixe{
  display: flex;
  justify-content: space-between;
}

.flex-flow{
  display: flex;
  flex-flow: row;
}
 .remark-box{
    border: 1px solid #ddd;
    font-size: 12px;
    color: #999;
    width: 500px;
    height: 60px;
    resize: none;
    padding: 6px;
    line-height: 18px;
    outline: 0;
}
.order{
  width: 100%;
  // padding: 15px;
  border: 1px solid #ddd;
  margin: 10px 0;
}
.dis{
  display: flex;
  justify-content: space-between;
}
.tb-item1{
  width: 35%;
}
.tb-item2{
  width: 10%;
}
.tb-item3{
  width: 15%;
}
.tb-item4{
  width: 25%;
}
.tb-bottom{
  background-color: #fff4e8;
  text-align: center;
  height: 100px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  span{
    color: #000;
  }
}
.shopheiz{
  width: 80%;
  text-align: left;
}
.order-tb {
  border-bottom: 2px solid #ddd;
  background-color: #f3f3f3;
    border-top: none;
    border-left: none;
    text-align: center;
    font-size: 13px;
    padding: 8px;
}
table {
  display: table;
  border-collapse: separate;
  box-sizing: border-box;
  text-indent: initial;
  border-spacing: 2px;
  border-color: grey;
}
.order-tb {
    width: 100%;
    text-align: center;
    font-size: 13px;
}
.btn{
  width: 90%;
  margin: 0 auto;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: #fff;
  background-color: $color-main;
  border-radius: 5px;
  cursor: pointer;
}
.bentc{
  color: #fff;
  background-color: #409eff;
  border-color: #409eff;
  display: inline-block;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    background: #fff;
    border: 1px solid #dcdfe6;
    color: #606266;
    -webkit-appearance: none;
    text-align: center;
    box-sizing: border-box;
    outline: 0;
    margin: 0;
    transition: .1s;
    font-weight: 500;
    padding: 8px 20px;
    font-size: 14px;
    border-radius: 4px;
}
.addres{
  border-bottom: 1px solid #ddd;
  width: 100%;
  height: 40px;
  margin-bottom: 10px;
  display: flex;
  align-content: center;
  justify-content: space-between;
}
.orderInfo {
  img{
    width: 150px;
    max-width: 100%;
    max-height: 100%;
  }
  background: #fff;
  box-shadow: 0 7px 23px 0 rgb(90 116 148 / 22%);
  border-radius: 10px;
}
</style>
